import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Card from './Card'

const UseEffectEx = () => {
    const [products,setProducts] = useState([])

    async function getProducts(){
        const reponse = await axios.get("https://dummyjson.com/products")
        const data =  await reponse.data.products
        setProducts(data.slice(0,20))
    }
    useEffect(()=>{
        getProducts()
    },[])
  return (
    <div>
        <h1 className='text-center'>Sasta Meesho</h1>
        <div className='row row-cols-4 gap-2 justify-content-center'>
        {
            products.map((product)=>{
                return (
                    // <div key={product.id} >
                    //     <ul>
                    //         <li> Title: {product.title}</li>
                    //         <li>Price: ${product.price}</li>
                    //         <img style={{width:"100px"}} src={product.thumbnail} alt="" />
                    //     </ul>
                    // </div>
                    <Card product={product}/>
                )
            })
        }

        </div>
        
       </div>
  )
}

export default UseEffectEx
import React from 'react'

const Footer = () => {
    const footerStyle = {
        backgroundColor:"pink"
    }
  return (
    <div style={footerStyle}>Footer</div>
  )
}

export default Footer

export function Home() {
    return(
        <div>
            <h1>Home Component</h1>
        </div>      
    );
}
    
export function About() {
    return(
        <div>
            <h1>
                About Component
            </h1>
        </div>
    );
}
    export function Contact(){
        return(
            <div>
                <h1>
                    Contact Component
                </h1>
            </div>
        );
    }
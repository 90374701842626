
//import { Example } from "./components/axios";

// import { Counter } from "./components/counter";
// import { F1 } from "./components/F1";
// import Header from "./components/header";
// import {Footer} from "./components/header";
// import { LifeCycle } from "./components/LifeCycle";

//import {Main} from "./components/Main"

import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { Navbar } from "./components_for_meeshow/Navbar";
import {HashRouter,Routes,Route} from "react-router-dom"
import { About, Contact, Home } from "./components_for_meeshow/ContentOne";
import Header from "./components_for_meeshow/Header";
import Footer from "./components_for_meeshow/Footer";
import UseEffectEx from "./components_for_meeshow/UseEffectEx";

import Main from "./components_for_meeshow/Main";



function App() {
  return (
  <div>
         
        
          <HashRouter>
          <Header/>
          <Main/>
        <Navbar/>
            <Routes>
              <Route path="/home" element={<Home/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/contact" element={<Contact/>}/>
            </Routes>
            <Footer/>

            
            <UseEffectEx/>
        {/* <Main myRole='learner'/> */}
        
          </HashRouter>

       
       


  </div>
    
  ); 
}

export default App;
